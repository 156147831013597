/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Box, Flex } from "rebass"
import { Styled } from "theme-ui"
import styled from "@emotion/styled"
import { MdInfoOutline, MdAccountCircle, MdChevronRight } from "react-icons/md"

import {
  Container,
  Layout,
  Section,
} from "@giraldomac/gatsby-theme-mmdbase/src/components/layout"
import { SEO } from "@giraldomac/gatsby-theme-mmdbase/src/components/elements"
import { PubHero } from "../../components/elements"
import theme from "@giraldomac/gatsby-theme-mmdbase/src/gatsby-plugin-theme-ui"

const BoxList = styled.ul`
  margin: 0;
  list-style: none;
  font-size: ${theme.fontSizes[2]};
  li {
    margin: 0;
    line-height: 1.25;
  }
  li a {
    align-items: center;
    color: white;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    justify-content: space-between;
    padding: 0.35em;
    text-decoration: none;
    transition: background-color 0.25s;
  }
  li a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const Index = ({ data }) => {
  const page = data.prismic.customer_service

  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />
      <PubHero title={RichText.asText(page.title)} />
      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box
              width={["full"]}
              sx={{
                backgroundColor: "wrapperbox",
                padding: [4, 5],
                textAlign: "center",
              }}
            >
              {RichText.render(page.content)}
            </Box>
          </Flex>
        </Container>

        <Container>
          <Flex flexWrap="wrap">
            <Box
              width={["full", 1 / 2]}
              sx={{
                backgroundColor: "blue.6",
                color: "white",
              }}
            >
              <Flex flexWrap="wrap">
                <Box width="full" sx={{ p: 4, backgroundColor: "blue.5" }}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb={3}
                  >
                    <Styled.h2 sx={{ color: "white" }}>Account</Styled.h2>
                    <MdAccountCircle sx={{ fill: "blue.6", fontSize: "3em" }} />
                  </Flex>
                  <p>
                    As a PUB customer, you can manage your account online at any
                    time, as well as learn more about what PUB has to offer.
                    Whether you are looking to pay your bill, or make
                    adjustments to your service or service location, you can do
                    so with ease.
                  </p>
                </Box>
                <Box width="full" sx={{ p: 4 }}>
                  <BoxList>
                    <li>
                      <Link to="/customer-service/account/pay-bill">
                        Pay My Bill{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/customer-service/account/start-service">
                        Start Service{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/customer-service/account/end-service">
                        End Service{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/customer-service/account/transfer-location">
                        Transfer Location{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/customer-service/account/change-address">
                        Change Mailing Address{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/customer-service/account/name-change">
                        Name Change{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/customer-service/account/customer-forms">
                        Additional Customer Forms{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                  </BoxList>
                </Box>
              </Flex>
            </Box>

            <Box
              width={["full", 1 / 2]}
              sx={{
                backgroundColor: "green.6",
                color: "white",
              }}
            >
              <Flex flexWrap="wrap">
                <Box width="full" sx={{ p: 4, backgroundColor: "green.5" }}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb={3}
                  >
                    <Styled.h2 sx={{ color: "white" }}>Information</Styled.h2>
                    <MdInfoOutline sx={{ fill: "green.6", fontSize: "3em" }} />
                  </Flex>
                  <p>
                    For customers or potential customers of PUB who would like
                    to learn about what we offer, this section is for you. Here
                    you will find various kinds of information about how PUB
                    operates and how we can help you.
                  </p>
                </Box>
                <Box width="full" sx={{ p: 4 }}>
                  <BoxList>
                    <li>
                      <Link to="customer-service/information/service-issues">
                        Service Issues{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="customer-service/information/policies-procedures">
                        Policies & Procedures{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="customer-service/information/rates-tariffs">
                        Rates & Tariffs{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="customer-service/information/reports-ordinances">
                        Reports & Ordinances{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="customer-service/information/resources">
                        Resources{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                  </BoxList>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query CustomerServiceQuery {
    prismic {
      customer_service(lang: "en-us", uid: "customer-service") {
        title
        content
        meta_title
        meta_description
        _linkType
      }
    }
  }
`
